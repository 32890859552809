<template>
 <div>
  <div v-if="loader">
    <b-flix-loader></b-flix-loader>
  </div>
  <b-flix-alert v-else-if="success" variant="success">
    {{ $t('message.successfullSaved') }}
  </b-flix-alert>
  <transition name="flixFadeIn" >
    <div v-if="!loader && !success">
      <b-flix-h3>{{ $tc('message.settings', 2) }}: {{ $tc('message.holidays', 2) }} </b-flix-h3>
      <div class="flex flex-center flex-gap-10 flix-form-group">
        <selector style="flex: 1" @save="setDuplicateFrom" :all="false" :empty="true"></selector>
        <b-flix-icon icon="chevron-right"></b-flix-icon>
        <selector style="flex: 1" @save="setDuplicateTo" :all="false" :empty="true"></selector>
        <b-flix-button :callback="saveDuplicate" variant="contained-green">{{ $tc('message.holidays', 2) }} {{ $t('message.duplicate') }}</b-flix-button>
      </div>
      <div class="flex flex-center flex-gap-10 flix-form-group">
        <selector style="flex: 1" @save="setDelete" :all="false" :empty="true"></selector>
        <b-flix-button v-if="!confirmDelete" variant="contained-green" :callback="function () { confirmDelete = true}">{{ $tc('message.holidayDelete', 2) }}</b-flix-button>
        <div v-if="confirmDelete" class="flex flex-center flex-gap-5"><span>{{ $tc('message.holidayDelete', 2) }}</span><a href="#" class="flix-btn flix-btn-default" @click.prevent="saveDelete">{{ $t('message.yes') }}</a><a href="#" class="flix-btn flix-btn-default" @click.prevent="confirmDelete=false">{{ $t('message.no') }}</a></div>
      </div>
      <div class="flix-form-group">
      <div class="flex flex-center flex-gap-10 flix-form-group">
        <selector style="flex: 1" @save="setGermanHolidays" :all="false" :empty="true"></selector>
        <b-flix-button variant="contained-green" :callback="saveGermanHolidays">{{ $t('message.holidayGerman', {year}) }}*</b-flix-button>
      </div>
      <div class="flix-text-right flix-small">* = {{ $t('message.holidayGermanList') }}</div>
      <div class="flix-text-right flix-small"><b>{{ $t('message.holidayGermanChange') }}</b></div>
      </div>
    </div>
  </transition>
  </div>
</template>
<script>
const holidaysImporter = require('./importer.js')
export default {
  name: 'holidayImporter',
  components: {
    selector () { return import('./selector.vue') }
  },
  props: {},
  data () {
    return {
      duplicate: {
        from: false,
        to: false
      },
      delete: {
        from: false
      },
      standard: {
        from: false
      },
      loader: false,
      success: false,
      year: new Date().getFullYear(),
      confirmDelete: false
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    setDuplicateFrom (val) {
      this.duplicate.from = val
    },
    setDuplicateTo (val) {
      this.duplicate.to = val
    },
    setDelete (val) {
      this.confirmDelete = false
      this.delete.from = val
    },
    setGermanHolidays (val) {
      this.standard.from = val
    },
    async saveDelete () {
      this.confirmDelete = false
      if (!this.delete.from) {
        return false
      }
      this.loader = true
      await holidaysImporter({
        flixPost: this.$flix_post,
        method: 'delete',
        filter: this.delete.from,
        user: this.$getUserVariables().user.md5_id
      })
      this.loader = false
      this.success = true
      await new Promise(resolve => setTimeout(resolve, 2000))
      this.success = false
      this.$emit('save')
    },
    async saveDuplicate () {
      if (!this.duplicate.from || !this.duplicate.to) {
        return false
      }
      if (this.duplicate.from === this.duplicate.to) {
        return false
      }
      this.loader = true
      await holidaysImporter({
        flixPost: this.$flix_post,
        method: 'duplicate',
        filter: this.duplicate.from,
        target: this.duplicate.to,
        user: this.$getUserVariables().user.md5_id
      })
      this.loader = false
      this.success = true
      await new Promise(resolve => setTimeout(resolve, 2000))
      this.success = false
      this.$emit('save')
    },
    async saveGermanHolidays () {
      if (!this.standard.from) {
        return false
      }

      this.loader = true
      await holidaysImporter({
        flixPost: this.$flix_post,
        method: 'standard',
        filter: this.standard.from,
        user: this.$getUserVariables().user.md5_id
      })
      this.loader = false
      this.success = true
      await new Promise(resolve => setTimeout(resolve, 2000))
      this.success = false
      this.$emit('save')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
