'use strict'

module.exports = async (settings) => {
  const handleData = async () => {
    const hd = await sendData('holidays/get', {}, settings.filter)

    if (!hd[0]) {
      return false
    }

    const newData = hd[1].filter((d) => { if (d.to === settings.filter) { return true } return false })

    if (!Object.keys(newData).length) {
      return false
    }

    if (settings.method === 'delete') {
      return await setDelete(newData)
    }

    if (settings.method === 'duplicate') {
      return await setDuplicate(newData)
    }
  }
  const setDuplicate = async (data) => {
    data.forEach(async (d) => {
      delete d.ID
      await sendData('holidays/save', d, settings.target)
    })
    return true
  }
  const setDelete = async (data) => {
    data.forEach(async (d) => {
      await sendData('holidays/delete', d.ID, settings.filter)
    })
    return true
  }
  const sendData = async (url, data, to) => {
    let saveData = ''
    switch (url) {
      case 'holidays/standard':
        saveData = {
          filter: to || settings.filter,
          user: settings.user
        }
        break
      case 'holidays/get':
        saveData = {
          filter: to || settings.filter,
          user: settings.user
        }
        break
      case 'holidays/save':
        saveData = {
          data: Object.assign(data, { filter: to || settings.filter, status: 'active' }),
          filter: to || settings.filter,
          user: settings.user
        }
        break
      case 'holidays/delete':
        saveData = {
          ID: data,
          filter: to || settings.filter,
          user: settings.user
        }
        break
    }
    return new Promise(
      (resolve) =>
        settings.flixPost({
          url: url,
          data: saveData,
          callback: (ret) => { resolve(ret.data) }
        })
    )
  }

  if (settings.method === 'standard') {
    await sendData('holidays/standard', false, settings.filter)
    return true
  }
  await handleData()
  return true
}
